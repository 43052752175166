<template>
  <vue-good-table
    :columns="columns"
    :rows="rows"
    :sort-options="{ enabled: true }"
    :search-options="{
      enabled: true,
      externalQuery: searchTerm,
    }"
    :pagination-options="{
      enabled: true,
      perPage: pageLength,
    }"
  >
    <div slot="emptystate" class="text-center">
      {{ $t('Global.Emptystate') }}
    </div>

    <template slot="table-row" slot-scope="props">
      <span v-if="props.column.field === 'totalWorkedHours'">
        {{ props.row.totalWorkedHours.toFixed(2).replace('.', ',') }}
      </span>
      <span v-else-if="props.column.field === 'totalCosts'">
        {{ props.row.totalCosts.toFixed(2).replace('.', ',') + ' €' }}
      </span>
    </template>

    <template slot="pagination-bottom" slot-scope="props">
      <div class="d-flex justify-content-between flex-wrap px-2 pb-2">
        <div class="d-flex align-items-center mb-0 mt-1 flex-wrap">
          <span class="text-nowrap">
            {{ $t('Global.ShowingOneOf') }}
          </span>
          <v-select
            v-model="pageLength"
            class="mx-1"
            style="min-width: 75px"
            :clearable="false"
            :options="['5', '10', '15']"
            @input="value => props.perPageChanged({ currentPerPage: value })"
          />
          <span class="text-nowrap">{{ $t('Global.EntriesShown') }}</span>
        </div>
        <div>
          <b-pagination
            :value="1"
            :total-rows="props.total"
            :per-page="pageLength"
            first-number
            last-number
            align="right"
            prev-class="prev-item"
            next-class="next-item"
            class="mt-1 mb-0"
            @input="value => props.pageChanged({ currentPage: value })"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </div>
    </template>
  </vue-good-table>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    vSelect,
    BPagination,
  },

  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    searchTerm: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      rowId: 0,
      pageLength: 5,
      columns: [
        {
          label: this.$t('Evaluations.TotalCost.CostCentre'),
          field: 'costCentre',
        },
        {
          label: this.$t('Evaluations.TotalCost.Area'),
          field: 'areaName',
        },
        {
          label: this.$t('Evaluations.TotalCost.Staff'),
          field: 'name',
        },
        {
          label: this.$t('Evaluations.TotalCost.ActualHours'),
          field: 'totalWorkedHours',
          type: 'number',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: this.$t('Evaluations.TotalCost.Costs'),
          field: 'totalCosts',
          type: 'number',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
      ],
    }
  },

  methods: {},
}
</script>
