<template>
  <section>
    <b-card>
      <h3 class="mb-1 font-weight-bolder">
        {{ $t('Global.Filters') }}
      </h3>
      <b-row align-h="between">
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Evaluations.TotalCost.DateSpan') }}
          </label>
          <b-form-group>
            <flat-pickr
              v-model="rangeDate"
              class="form-control"
              :config="{
                mode: 'range',
                maxDate: currentMaxDate,
                defaultDate: [startDate, endDate],
                dateFormat: 'd.m.Y',
                onChange: handleChange,
              }"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t('Evaluations.TotalCost.CostCentre') }}
          </label>
          <v-select
            v-model="filterCostCentre"
            :placeholder="$t('Global.Filter')"
            :options="costCentre"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div
        class="custom-search d-flex justify-content-between align-items-center mb-2 px-2 pt-2"
      >
        <h2 class="m-0 font-weight-bolder">
          {{ $t('Global.All') }} {{ $t('Evaluations.TotalCost.TotalCost') }}
        </h2>
        <div class="d-flex align-items-center">
          <b-form-input
            v-model="searchTerm"
            :placeholder="$t('Global.Search')"
            type="text"
            class="d-inline-block search"
          />
          <feather-icon
            class="download-icon"
            icon="DownloadIcon"
            @click="download"
          />
        </div>
      </div>
      <Table :rows="rowData" :search-term="searchTerm" />
    </b-card>
  </section>
</template>

<script>
import moment from 'moment'
import { BFormInput, BCard, BRow, BCol, BFormGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Table from './components/table.vue'
import { initialLoadingForTotalCosts, load } from '../utility/load'

export default {
  components: {
    BFormInput,
    BCard,
    Table,
    BFormGroup,
    BRow,
    BCol,
    flatPickr,
    vSelect,
  },

  data: () => ({
    filterCostCentre: null,
    searchTerm: '',
    loading: false,
    rangeDate: null,
    startDate: null,
    endDate: null,
    layers: [],
    contracts: null,
    evaluationForTotalCost: [],
    costCentre: [],
    excelTable: null,
  }),

  computed: {
    currentMaxDate() {
      return new Date(Date.now() - 24 * 60 * 60 * 1000)
    },
    lastMonthStart() {
      return moment().subtract(1, 'month').startOf('month')
    },
    lastMonthEnd() {
      return moment().subtract(1, 'month').endOf('month')
    },
    rowData() {
      if (this.filterCostCentre !== null && this.evaluationForTotalCost) {
        const copyOfEvaluationForTotalCost = JSON.parse(
          JSON.stringify(this.evaluationForTotalCost),
        )
        const filteredData = copyOfEvaluationForTotalCost.filter(
          x => x.costCentre === this.filterCostCentre,
        )
        return filteredData
      }
      return this.evaluationForTotalCost
    },
  },

  watch: {
    evaluationForTotalCost() {
      this.evaluationForTotalCost.forEach(obj => {
        if (
          obj.costCentre !== null &&
          !this.costCentre.includes(obj.costCentre)
        ) {
          this.costCentre.push(obj.costCentre)
        }
      })
    },
    rangeDate() {
      const formattedStartDate = moment(this.startDate).format('YYYY-MM-DD')
      const formattedEndDate = moment(this.endDate).format('YYYY-MM-DD')
      this.loadNewDate(formattedStartDate, formattedEndDate)
    },
  },

  async created() {
    this.startDate = this.lastMonthStart.toDate()
    this.endDate = this.lastMonthEnd.toDate()
    const formattedStartDate = moment(this.startDate).format('YYYY-MM-DD')
    const formattedEndDate = moment(this.endDate).format('YYYY-MM-DD')

    await this.initialLoadingForTotalCosts(formattedStartDate, formattedEndDate)

    if (this.employeesEvaluations) {
      this.employeesEvaluations[0].layers.forEach(layer => {
        this.layers.push(layer.layerName)
      })
    }
  },

  methods: {
    handleChange(selectedDates) {
      const [startDate, endDate] = selectedDates
      this.startDate = startDate
      this.endDate = endDate || startDate
    },

    async loadNewDate(startDateString, endDateString) {
      await this.initialLoadingForTotalCosts(startDateString, endDateString)
    },

    async initialLoadingForTotalCosts(...args) {
      return initialLoadingForTotalCosts.call(this, ...args)
    },

    async download() {
      try {
        const response = await this.$axios.post(
          `evaluations/total-cost/download/${moment(this.startDate).format(
            'YYYY-MM-DD',
          )}/${moment(this.endDate).format('YYYY-MM-DD')}`,
          this.rowData,
          { responseType: 'blob' },
        )
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        if (this.startDate !== this.endDate) {
          link.setAttribute(
            'download',
            `Gesamtkosten_${moment(this.startDate).format(
              'YYYY-MM-DD',
            )}_${moment(this.endDate).format('YYYY-MM-DD')}.zip`,
          )
        } else {
          link.setAttribute(
            'download',
            `Gesamtkosten_${moment(this.startDate).format('YYYY-MM-DD')}.zip`,
          )
        }
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } catch (error) {
        this.$alert.error()
      }

      this.visible = false
    },

    async load(...args) {
      return load.call(this, ...args)
    },
  },
}
</script>

<style lang="scss" scoped>
.download-icon {
  margin-left: 25px;
  width: 30px;
  height: 30px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
